export const environment = {
  production: true,
  carriersRealmName: "carriers",
  dashboardUrl: "https://dashboard-dev.warehousing.omniopti.eu",
  yamasUrl: "https://yamas-dev.warehousing.omniopti.eu",
  authServerUrl: "https://login-dev.warehousing.omniopti.eu/auth",
  languages: [
    {
      name: "SLO 🇸🇮",
      link: "https://sl.owl-dev.warehousing.omniopti.eu",
      code: "sl",
    },
    {
      name: "ENG 🇬🇧",
      link: "https://owl-dev.warehousing.omniopti.eu",
      code: "en-US",
    },
  ],
  apiUrl: "https://api.owl-dev.warehousing.omniopti.eu",
};
